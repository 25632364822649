








































import {Component, Prop, Vue} from 'vue-property-decorator';
import {BrokerRecord} from '@/interfaces';
import BrokerTable from "@/modules/brokerTable";
import AddBroker from "@/components/SelectiveBrokerCompare/AddBroker.vue";
import ModifyBroker from "@/components/SelectiveBrokerCompare/ModifyBroker.vue";
import Modal from "@/components/Modal.vue";

@Component({
  components: {AddBroker, ModifyBroker, Modal}
})
export default class ModifyComparisonModal extends Vue {

  @Prop(Array) readonly brokers: BrokerRecord[] | undefined;

  @Prop(Boolean) readonly isOpen: boolean | undefined;

  /**
   * @private
   */
  private get isMaxBrokers(): boolean {
    return ( this.brokers?.length ?? 0 ) >= this.maxBrokers;
  }

  /**
   * @private
   */
  private get maxBrokers(): number {
    return (this.$store.state.brokerTable as BrokerTable).maxComparingBrokers;
  }

  /**
   * @private
   */
  private updateUrl(): void {
    const segment = this.$store.getters.alphabetizedSelectedBrokers
      .map((broker: BrokerRecord) => broker.broker.slug)
      .join('/');

    this.$router.replace(`/brokers/compare/${segment}`);
  }
}
