





















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class ShareButton extends Vue {
  @Prop(Boolean) readonly open: boolean | undefined;
}
