







































import { Component, Prop, Vue } from 'vue-property-decorator';
import TableRow from './TableRow.vue'
import {BrokerCategory, BrokerField, BrokerRecord} from '@/interfaces';
import {BrokerFieldSlug} from "@/enums";
import BrokerFieldSource from "@/components/BrokerFieldSource.vue";
import BrokerFieldUpdated from "@/components/BrokerFieldUpdated.vue";
import BrokerFieldComment from "@/components/BrokerFieldComment.vue";

@Component({
components: {
  BrokerFieldComment,
  BrokerFieldUpdated,
  BrokerFieldSource,
    TableRow
  },
})
export default class TableBody extends Vue {

  @Prop(Object) readonly category: BrokerCategory | undefined;

  @Prop(Array) readonly brokers: BrokerRecord[] | undefined;

  // @TODO need to move this to the VueX store
  private showMore = false;

  private get brokerFields(): BrokerField[] {
    return this.$store.getters.brokerFields(this.category?.slug)
  }

  private get moreInfo(): Record<BrokerFieldSlug, boolean> {
    return this.$store.state.selectiveBrokerCompare.moreInfo;
  }
}
