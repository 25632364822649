
































































































import {Component, Vue} from 'vue-property-decorator';
import CategoryAccordion from '@/components/CategoryAccordion.vue'
import TableBody from './TableBody.vue'
import BackButton from '@/components/BackButton.vue'
import {BrokerCategory, BrokerRecord} from '@/interfaces';
import BrokerLogoLink from "@/components/BrokerLogoLink.vue";
import ShareButton from "@/components/ShareButton.vue";
import ShareAction from "@/components/ShareAction.vue";
import Actions from "@/components/Actions.vue";
import Modal from "@/components/Modal.vue";
import ModifyBroker from "@/components/SelectiveBrokerCompare/ModifyBroker.vue";
import Broker from "@/modules/broker";
import {ModifyComparisonState} from "@/enums";
import ModifyComparisonModal from "@/components/SelectiveBrokerCompare/ModifyComparisonModal.vue";

@Component({
components: {
  ModifyComparisonModal,
  ModifyBroker,
  Actions,
  ShareAction,
  ShareButton,
  Modal,
  BrokerLogoLink,
    CategoryAccordion,
    TableBody,
    BackButton
  },
})
export default class BrokersComparison extends Vue {

  private isOpen = false;

  private actionsOpen = false;

  /**
   * @private
   */
  private get brokerColumnWidth(): string {
    return `${85 / this.brokers.length}%`;
  }

  /**
   * @private
   */
  private get brokers(): BrokerRecord[] {
    return this.$store.getters.sortedSelectedBrokers;
  }

  /**
   * @private
   */
  private get comparisonBetween(): string {
    return this.$store.getters.selectedBrokersTitle;
  }

  /**
   * @private
   */
  private get categories(): BrokerCategory[] {
    return this.$store.state.broker.categories;
  }

  /**
   * @private
   */
  private get shareData(): ShareData {
    return {
      text: `Compare ${this.comparisonBetween} on CryptoBrokerCompare.com.`,
      title: `${this.comparisonBetween} Broker Comparison`,
      url: document.URL
    }
  }
}
