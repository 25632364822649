<template>
  <div>
    <BrokersComparison/>
  </div>
</template>

<script>
import BrokersComparison from '@/components/SelectiveBrokerCompare/BrokersComparison.vue'
import store from '@/store';
import title from '@/title';
import data from '@/data';
import meta from '@/meta';

export default {
  name: 'brokers-comparison-table',
  beforeRouteEnter: (to, from, next) => {
    store.commit('setSelectiveBrokerCompareSlugs', (to.params.slug || '').split('/') || []);

    // Wait for data to load
    data.then(() => {

      // If there are less than two brokers
      if (store.getters.alphabetizedSelectedBrokers.length < 2) {
        next('/400');
      }

      store.commit('replaceBackButtonText', `Back to ${store.getters.selectedBrokersTitle} Comparison`);

      meta({
        title: title(`${store.getters.selectedBrokersTitle} Broker Comparison`),
        description: `View the ${store.getters.selectedBrokersTitle} broker comparison. Compare maker & taker fees, spreads, and other details.`,
        url: to.path,
        keywords: [...store.getters.alphabetizedSelectedBrokers.map(broker => broker.broker.name), 'comparison', 'vs.', 'versus', 'maker', 'taker', 'spread', 'commission', 'deposit', 'withdrawal', 'fees', 'exchange', 'broker'],
      });

      next();
    });
  },
  components: {
    BrokersComparison
  }
}
</script>