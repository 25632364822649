

































import { Component, Prop, Vue } from 'vue-property-decorator';

import TableCell from './TableCell.vue'
import {BrokerCategory, BrokerField, BrokerRecord} from '@/interfaces';
import {BrokerFieldSlug, SortOrder} from "@/enums";
import SelectiveBrokerCompare from "@/modules/selectiveBrokerCompare";
import MoreInfoButton from "@/components/MoreInfoButton.vue";

@Component({
components: {
  MoreInfoButton,
    TableCell
  },
})
export default class TableRow extends Vue {

  @Prop(Object) readonly category: BrokerCategory | undefined;

  @Prop(Object) readonly field: BrokerField | undefined;

  @Prop(Array) readonly brokers: BrokerRecord[] | undefined;

  /**
   * @private
   */
  private get SortOrder(): typeof SortOrder {
    return SortOrder;
  }

  /**
   * @private
   */
  private get isMoreInfo(): Record<BrokerFieldSlug, boolean> {
    return this.field?.slug ? this.$store.state.selectiveBrokerCompare.moreInfo[this.field.slug] : false;
  }

  /**
   * @private
   */
  private get getIsSortField(): boolean {
    return (this.$store.state.selectiveBrokerCompare as SelectiveBrokerCompare).sortField === this.field?.slug;
  }

  /**
   * @private
   */
  private get sortOrder(): SortOrder {
    return (this.$store.state.selectiveBrokerCompare as SelectiveBrokerCompare).sortOrder
  }

  /**
   * @private
   */
  private toggleMoreInfo() {
    this.$store.commit('toggleSelectiveBrokerCompareMoreInfo', this.field?.slug);
  }

  /**
   * @private
   */
  private updateSort() {
    this.$store.commit('updateSelectiveBrokerCompareSort', this.field?.slug);
  }
}
