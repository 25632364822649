


























import { Component, Prop, Vue } from 'vue-property-decorator';
import FieldAccordion from './FieldAccordion.vue'
import {BrokerCategory, BrokerField, BrokerRecord} from '@/interfaces';

@Component({
components: {
    FieldAccordion
  },
})
export default class CategoryAccordion extends Vue {
  
  @Prop(Object) readonly brokerCategory: BrokerCategory | undefined;

  @Prop(Array) readonly brokers: BrokerRecord[] | undefined;

  private isOpen = false;

  private get brokerFields(): BrokerField[] {
    return this.$store.getters.brokerFields(this.brokerCategory?.slug);
  }

}
