

















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {BrokerRecord} from '@/interfaces';
import Broker from "@/modules/broker";
import {ModifyComparisonState} from "@/enums";
import SelectiveBrokerCompare from "@/modules/selectiveBrokerCompare";

@Component
export default class AddBroker extends Vue {

  @Prop(Array) readonly brokers: BrokerRecord[] | undefined;

  @Prop(Object) readonly broker: BrokerRecord | undefined;

  private rowModifyComparisonState: ModifyComparisonState = ModifyComparisonState.List;

  /**
   * Broker ID of the new broker
   *
   * @private
   */
  private brokerId = 0;

  private get ModifyComparisonState() {
    return ModifyComparisonState;
  }

  private get newBroker(): BrokerRecord | undefined {
    return this.allBrokers.find(broker => broker.broker.id === this.brokerId);
  }

  private get addBroker(): string {
    const broker = this.newBroker;

    return broker ? `Add ${broker.broker.name} to broker comparison` : '';
  }

  private get modalModifyComparisonState(): ModifyComparisonState {
    return (this.$store.state.selectiveBrokerCompare as SelectiveBrokerCompare).modifyComparisonState;
  }

  private set modalModifyComparisonState(modifyComparisonState: ModifyComparisonState) {
    this.$store.commit('setModifyComparisonState', modifyComparisonState);
  }

  /**
   * @private
   */
  private get allBrokers(): BrokerRecord[] {
    return (this.$store.state.broker as Broker).brokers
  }

  /**
   * Add a new broker to the comparison
   *
   * @private
   */
  private add(): void {
    const broker = this.newBroker;

    if (broker) {
      // Reset add to comparison select input
      this.brokerId = 0;

      const slugs = (this.$store.state.selectiveBrokerCompare as SelectiveBrokerCompare).slugs;

      slugs.push(broker.broker.slug);

      this.$store.commit('setSelectiveBrokerCompareSlugs', slugs);

      this.rowModifyComparisonState = ModifyComparisonState.List;
      this.modalModifyComparisonState = ModifyComparisonState.List;

      this.$emit('change');
    }
  }

  /**
   * Show broker select input
   *
   * @private
   */
  private select(): void {
    this.rowModifyComparisonState = ModifyComparisonState.Edit;
    this.modalModifyComparisonState = ModifyComparisonState.Edit;

    this.$nextTick(() => {
      if (this.$refs.select instanceof HTMLSelectElement) {
        this.$refs.select.focus();
      }
    });
  }

  /**
   * Cancel add broker
   *
   * @private
   */
  private cancel(): void {
    this.rowModifyComparisonState = ModifyComparisonState.List;
    this.modalModifyComparisonState = ModifyComparisonState.List;
    this.brokerId = 0;
  }
}
