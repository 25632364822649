













import {Component, Prop, Vue} from 'vue-property-decorator';
import Broker from "@/modules/broker";

@Component
export default class ShareAction extends Vue {

  @Prop(Object) readonly shareData: ShareData | undefined;

  /**
   * @private
   */
  private get canShare(): boolean {
    return (this.$store.state.broker as Broker).canShare;
  }

  /**
   * @private
   */
  private onClick() {
    return navigator.share(this.shareData).then(() => {
      this.$emit('share');
    }).catch(() => {
      this.$emit('error');
    });
  }
}
