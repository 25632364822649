































import { Component, Prop, Vue } from 'vue-property-decorator';
import AccordionTableRow from './AccordionTableRow.vue'
import {BrokerField, BrokerRecord} from '@/interfaces';

@Component({
components: {
    AccordionTableRow
  },
})
export default class FieldAccordion extends Vue {
  
  @Prop(Object) readonly brokerField: BrokerField | undefined;

  @Prop(Array) readonly brokers: BrokerRecord[] | undefined;

  private isOpen = false;

}
