
































import { Component, Prop, Vue } from 'vue-property-decorator';
import {BrokerField, BrokerRecord, FieldData} from '@/interfaces';
import { BrokerFieldSlug } from '@/enums';
import MoreInfoButton from "@/components/MoreInfoButton.vue";
import Modal from "@/components/Modal.vue";
import BrokerFieldUpdated from "@/components/BrokerFieldUpdated.vue";
import BrokerFieldSource from "@/components/BrokerFieldSource.vue";
import BrokerFieldComment from "@/components/BrokerFieldComment.vue";

@Component({
  components: {
    BrokerFieldComment,
    BrokerFieldSource,
    BrokerFieldUpdated,
    MoreInfoButton,
    Modal
  },
})
export default class AccordionTableRow extends Vue {
  
  @Prop(Object) readonly brokerField: BrokerField | undefined;

  @Prop(Object) readonly broker: BrokerRecord | undefined;

  private isMore = false;

  private get updatedAt(): string {
    return this.fieldData.updatedAt ? new Date(this.fieldData.updatedAt).toLocaleDateString() : '';
  }

  private get fieldData(): FieldData {

    return this.broker?.fields[this.brokerField?.slug as BrokerFieldSlug] ||  {
      value: '',
      html: '',
      sourceUrl: '',
      comment: '',
      createdAt: '',
      updatedAt: '',
    };
  }

  

}
