























































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {BrokerRecord} from '@/interfaces';
import Broker from "@/modules/broker";
import uid from "@/uid";
import {ModifyComparisonState} from "@/enums";
import SelectiveBrokerCompare from "@/modules/selectiveBrokerCompare";

@Component
export default class ModifyBroker extends Vue {

  @Prop(Array) readonly brokers: BrokerRecord[] | undefined;

  @Prop(Object) readonly broker: BrokerRecord | undefined;

  private rowModifyComparisonState: ModifyComparisonState = ModifyComparisonState.List;

  private uid = 0;

  /**
   * Broker ID of the new broker
   *
   * @private
   */
  private brokerId = 0;

  private get ModifyComparisonState() {
    return ModifyComparisonState;
  }

  private get newBroker(): BrokerRecord | undefined {
    return this.allBrokers.find(broker => broker.broker.id === this.brokerId);
  }

  private get confirmSwap(): string {
    const broker = this.newBroker;

    return this.broker && broker ? `Swap ${this.broker.broker.name} with ${broker.broker.name}` : '';
  }

  private get modalModifyComparisonState(): ModifyComparisonState {
    return (this.$store.state.selectiveBrokerCompare as SelectiveBrokerCompare).modifyComparisonState;
  }

  private set modalModifyComparisonState(modifyComparisonState: ModifyComparisonState) {
    this.$store.commit('setModifyComparisonState', modifyComparisonState);
  }

  /**
   * @private
   */
  private get isMinComparingBrokers(): boolean {
    return (this.brokers?.length ?? 0) <= 2;
  }

  /**
   * @private
   */
  private get allBrokers(): BrokerRecord[] {
    return (this.$store.state.broker as Broker).brokers
  }

  /**
   * Show broker select input
   *
   * @private
   */
  private select(): void {
    this.rowModifyComparisonState = ModifyComparisonState.Edit;
    this.modalModifyComparisonState = ModifyComparisonState.Edit;

    this.$nextTick(() => {
      if (this.$refs.select instanceof HTMLSelectElement) {
        this.$refs.select.focus();
      }
    })
  }

  /**
   * Swap one broker for another
   *
   * @private
   */
  private swap(): void {

    this.rowModifyComparisonState = ModifyComparisonState.List;

    const slugs = (this.$store.state.selectiveBrokerCompare as SelectiveBrokerCompare).slugs,
      index = slugs.indexOf(this.broker?.broker?.slug ?? ''); // Index of current broker in array of slugs

    if (index >= 0) {
      slugs.splice(index, 1, this.newBroker?.broker?.slug ?? ''); // Replace slug of current broker with slug of new broker
    }

    this.$store.commit('setSelectiveBrokerCompareSlugs', slugs);

    this.rowModifyComparisonState = ModifyComparisonState.List;
    this.modalModifyComparisonState = ModifyComparisonState.List;

    this.$emit('change');
  }

  /**
   * Cancel broker swap
   *
   * @private
   */
  private cancel(): void {
    this.rowModifyComparisonState = ModifyComparisonState.List
    this.modalModifyComparisonState = ModifyComparisonState.List;
  }

  /**
   * @private
   */
  private remove(): void {

    this.rowModifyComparisonState = ModifyComparisonState.List;

    // If broker is undefined
    if (!this.broker) {
      return;
    }

    const slugs = (this.$store.state.selectiveBrokerCompare as SelectiveBrokerCompare).slugs,
      index = slugs.indexOf(this.broker.broker.slug);

    if (index >= 0) {
      slugs.splice(index, 1);
    }

    this.$store.commit('setSelectiveBrokerCompareSlugs', slugs);

    this.rowModifyComparisonState = ModifyComparisonState.List;
    this.modalModifyComparisonState = ModifyComparisonState.List;

    this.$emit('change');
  }

  public mounted() {
    this.uid = uid();
  }
}
