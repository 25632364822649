








import { Component, Prop, Vue } from 'vue-property-decorator';
import {BrokerCategory, BrokerField, BrokerRecord, FieldData} from '@/interfaces';
import { BrokerFieldSlug } from '@/enums';

@Component
export default class TableCell extends Vue {

  @Prop(Object) readonly category: BrokerCategory | undefined;

  @Prop(Object) readonly broker: BrokerRecord | undefined;

  @Prop(Object) readonly field: BrokerField | undefined;

  private get fieldValue(): FieldData {

    return this.broker?.fields[this.field?.slug as BrokerFieldSlug] ?? {
      value: '',
      html: '',
      sourceUrl: '',
      createdAt: '',
      updatedAt: '',
      comment: '',
    }
  }
}
