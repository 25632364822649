







import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class MoreInfoButton extends Vue {

  @Prop(Boolean) readonly isMore: boolean | undefined;

}
